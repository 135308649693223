import React from "react";
import baner from "../../assets/images/baner1.png";
import baner2 from "../../assets/images/baner-2.png";
import "./BanerSection.scss";

const BanerSection = ({ title, description, reverseOrder }) => {
  return (
    <div className={"BanerSection" + (reverseOrder ? " reverse" : "")}>
      {reverseOrder && <img src={baner2} alt="Baner" />}
      <div className="baner-content">
        <h1 className="text-main-600">{title}</h1>
        <p className="max-w-md text-main-600">{description}</p>
      </div>
      {!reverseOrder && <img src={baner} alt="Baner" />}
    </div>
  );
};

export default BanerSection;
